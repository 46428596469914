import { AxiosError } from 'axios';
import { $api } from './interceptor';
import { toast } from 'App';

const communicationApi = () => {
  const pushTelegramMessage = async ({
    message,
    imagesUrls,
  }: {
    message: string;
    imagesUrls?: string[];
  }) => {
    const payload = {
      text: imagesUrls?.length ? undefined : message,
      caption: imagesUrls?.length ? message : undefined,
      imagesUrls,
    };

    try {
      await $api.post('/api/telegram/send', {
        ...payload,
      });
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message || error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  return {
    pushTelegramMessage,
  };
};

export const communicationService = communicationApi();
