import axios, { AxiosInstance } from 'axios';
import { toast } from 'App';
import { getAccessToken } from 'utils/auth';
import { API_URL } from './interceptor';

interface UploadedImage {
  imageUrl: string;
  imageName: string;
}

export class UploadImage {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    });
  }

  async upload(files: File[]) {
    try {
      const accessToken = getAccessToken();
      const newFormData = new FormData();
      files.forEach(file => {
        newFormData.append('files', file, file.name);
      });

      const { data } = await this._api.post<UploadedImage[]>(
        'api/files/admin/upload',
        newFormData,
        {
          headers: {
            authorization: accessToken,
          },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);

      if (axios.isAxiosError(error)) {
        toast({
          title: 'Error occured.',
          description: error.response.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }

      throw new Error('Something went wrong');
    }
  }
}

export const uploadImage = new UploadImage();
