import { createStandaloneToast } from '@chakra-ui/toast';
import { AxiosError } from 'axios';
import {
  DiscordTaskType,
  Task,
  TaskStatus,
  TaskType,
  TelegramTaskType,
} from 'views/types/tasks';
import { $api } from './interceptor';
import { TaskFormScheme } from 'views/admin/marketplace/scheme/taksForm';

export interface TaskPayload {
  redirectUrl?: string;
  title: { [key: string]: string };
  description: { [key: string]: string };
  inviteCount?: number;
  targetLanguageGroups?: string[] | null;
  targetCountryGroups?: string[] | null;
  type: TaskType;
  rewardPointsAmount: number;
  order?: number | null;
  iconUrl?: string | null;
  status: TaskStatus;
  userCriteria: {
    isPremiumCriteria?: boolean;
    devicesCriteria?: string[];
    countriesCriteria?: string[];
    languageCriteria?: string[];
  };
  rewardTicketsAmount?: number;
  projectId?: string;
  customRewards?: string[];
  maxSuccessfullyCompletions?: number;
  telegramTask?: {
    telegramResourceId: number;
    type: TelegramTaskType;
  };
  discordTask?: {
    resourceId: string;
    type: DiscordTaskType;
  };
}

export interface TaskDetailsState {
  description: string;
  availableFromLevel: number;
  title: string;
  rewardPointsAmount: number;
  isActive: boolean;
  redirectUrl?: string;
  type: string;
  isFeaturedTask: boolean;
  iconUrl?: string;
  relativeRewardPercent?: number;
  projectId?: number;
}

export type SetPagesCount = (count: number) => void;

export const { toast } = createStandaloneToast();

const tasksApi = () => {
  const getTasks = async () => {
    const { data } = await $api.get('/api/tasks/list');
    return data;
  };

  const createTaskSubmit = async (data: TaskFormScheme) => {
    const targetLanguages = Array.isArray(data.task.targetLanguageGroups)
      ? data.task.targetLanguageGroups.filter(Boolean)
      : [];

    const targetCountries = Array.isArray(data.task.targetCountryGroups)
      ? data.task.targetCountryGroups.filter(Boolean)
      : [];

    if (targetCountries.length === 0) {
      targetCountries.push('en', 'ru');
    }

    if (targetLanguages.length === 0) {
      targetLanguages.push('en', 'ru');
    }

    const title: { [key: string]: string } = {};
    const description: { [key: string]: string } = {};

    targetLanguages.forEach(lang => {
      title[lang] = data.task.title[lang] || '';
      description[lang] = data.task.description[lang] || '';
    });

    const payload: {
      task: TaskPayload;
      targetCountries?: string[];
    } = {
      task: {
        targetCountryGroups: targetCountries,
        targetLanguageGroups: targetLanguages,
        title,
        description,
        type: data.task.type,
        rewardPointsAmount: +data.task.rewardPointsAmount,
        order: data.task.order ? +data.task.order : 0,
        iconUrl: data.task.iconUrl ? data.task.iconUrl : undefined,
        userCriteria: data.task.userCriteria,
        redirectUrl: data.task.redirectUrl,
        inviteCount: data.referralTask.inviteCount
          ? +data.referralTask.inviteCount
          : undefined,
        status: data.task.isActive ? TaskStatus.ACTIVE : TaskStatus.INACTIVE,
      },
    };

    switch (data.task.type) {
      case TaskType.TELEGRAM:
        payload.task.telegramTask = {
          telegramResourceId: +data.telegramTask?.resourceId,
          type: data.telegramTask?.taskType || TelegramTaskType.SUBSCRIBE,
        };
        await postTelegramTask(payload);
        return;
      case TaskType.SNAPSHOT:
        await postSnapshotTask(payload.task);
        return;
      case TaskType.DISCORD:
        payload.task.discordTask = {
          resourceId: data.discordTask?.resourceId,
          type: data.discordTask?.type || DiscordTaskType.JOIN_SERVER,
        };
        await postDiscordTask(payload);
        break;
      default:
        return;
    }
  };

  const patchTask = async (taskID: string, data: TaskFormScheme) => {
    const targetLanguages = Array.isArray(data.task.targetLanguageGroups)
      ? data.task.targetLanguageGroups.filter(Boolean)
      : [];

    const targetCountries = Array.isArray(data.task.targetCountryGroups)
      ? data.task.targetCountryGroups.filter(Boolean)
      : [];

    if (targetCountries.length === 0) {
      targetCountries.push('en', 'ru');
    }

    if (targetLanguages.length === 0) {
      targetLanguages.push('en', 'ru');
    }

    const title: { [key: string]: string } = {};
    const description: { [key: string]: string } = {};

    targetLanguages.forEach(lang => {
      title[lang] = data.task.title[lang] || '';
      description[lang] = data.task.description[lang] || '';
    });

    const requestBody: Record<string, unknown> = {
      enabled: data.task.isActive,
      rewardPointsAmount: +data.task.rewardPointsAmount,
      redirectUrl: data.task.redirectUrl,
      targetCountryGroups: targetCountries,
      targetLanguageGroups: targetLanguages,
      status: data.task.isActive ? TaskStatus.ACTIVE : TaskStatus.INACTIVE,
      iconUrl: data.task.iconUrl ? data.task.iconUrl : undefined,
      order: data.task.order ? +data.task.order : 0,
      inviteCount: data.referralTask.inviteCount
        ? +data.referralTask.inviteCount
        : undefined,
    };

    requestBody.title = title;
    requestBody.description = description;

    const missingLanguages = targetLanguages.filter(
      lang => !title[lang] || !description[lang]
    );

    if (missingLanguages.length > 0) {
      throw new Error(
        `Missing translations for languages: ${missingLanguages.join(', ')}`
      );
    }

    try {
      await $api.patch(`/api/tasks/${taskID}`, requestBody);

      toast({
        title: 'Task updated.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const getTasksFilters = async () => {
    try {
      const { data } = await $api.get('/api/users/target-filters');

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getPaginatedTasks = async ({
    page = 0,
    pageSize = 10,
    searchValue,
  }: {
    page?: number;
    pageSize?: number;
    searchValue?: string;
  }) => {
    const { data } = await $api.get('/api/tasks/list', {
      params: { page: page + 1, limit: pageSize, search: searchValue },
    });

    return data;
  };

  const deleteTask = async (taskID: string) => {
    try {
      const response = await $api.delete(`api/tasks/${taskID}`);
      if (response.status === 200) {
        toast({
          title: 'Task successfully deleted.',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error',
          description: error.response?.data.message,
          status: 'error',
        });
      }
      throw error;
    }
  };

  const postSnapshotTask = async (payload: TaskPayload) => {
    try {
      await $api.post('/api/tasks/snapshot', payload);
      toast({
        title: 'Snapshot task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postDiscordTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/discord', {
        task: payload.task,
        discordTask: {
          resourceId: payload.task.discordTask?.resourceId,
          type: payload.task.discordTask?.type,
        },
      });
      toast({
        title: 'Discord task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postTelegramTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/telegram', {
        task: payload.task,
        telegramTask: {
          telegramResourceId: payload.task.telegramTask?.telegramResourceId,
          type: payload.task.telegramTask?.type,
        },
      });
      toast({
        title: 'Telegram task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const approveAllTelegramTasks = async () => {
    try {
      await $api.post(`api/telegram-tasks/all/approve`);

      toast({
        title: 'All tasks approved.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleSubmitOrder = async (order: Record<Task['id'], string>) => {
    try {
      const entries = Object.entries(order);

      for (let i = 0; i < entries.length; i++) {
        await $api.patch(`api/tasks/${entries[i][0]}`, {
          order: +entries[i][1],
        });
      }

      toast({
        title: 'Order updated.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const createMessage = async (message: string) => {
    const url = '/api/telegram/send';

    await $api.post(url, { message });
  };

  return {
    getTasks,
    deleteTask,
    createTaskSubmit,
    patchTask,
    getPaginatedTasks,
    approveAllTelegramTasks,
    getTasksFilters,
    handleSubmitOrder,
    createMessage,
  };
};

export const tasksService = tasksApi();
