import { useFormContext, useWatch } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import TextArea from 'components/fields/TextArea';
import Select from 'components/fields/Select';
import Switch from 'components/switch';
import { TaskFormScheme } from '../../scheme/taksForm';
import { TaskType } from 'views/types/tasks';
import getCountries from 'views/services/getCountries';
import getLanguages from 'views/services/getLanguages';
import { preventScrollOnNumberInput } from 'utils/input';
import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { uploadImage } from 'views/services/uploadImage';
import ClipLoader from 'react-spinners/ClipLoader';

interface TaskFormProps {
  isEdit: boolean;
}

export function TaskForm({ isEdit }: TaskFormProps) {
  const form = useFormContext<TaskFormScheme>();
  const [countries, setCountries] = useState<
    { id: string; code: string; name: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [languages, setLanguages] = useState<{ code: string }[]>([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isIconUploading, setIsIconUploading] = useState(false);

  const targetLanguageGroups =
    useWatch({ control: form.control, name: 'task.targetLanguageGroups' }) ||
    [];
  const targetCountryGroups =
    useWatch({ control: form.control, name: 'task.targetCountryGroups' }) || [];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        alert('Please upload a valid image file (JPEG or PNG)');
        return;
      }

      setIsIconUploading(true);
      uploadImage
        .upload([file])
        .then(resp => {
          const imageUrl = resp[0].imageUrl;
          setSelectedFile(imageUrl);
          form.setValue('task.iconUrl', imageUrl, {
            shouldValidate: true,
            shouldDirty: true,
          });
        })
        .finally(() => {
          setIsIconUploading(false);
        });
    }
  };

  const handleRemoveFile = () => {
    form.setValue('task.iconUrl', '');
    setSelectedFile(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedCountries = await getCountries();
        const fetchedLanguages = await getLanguages();
        setCountries(fetchedCountries);
        setLanguages(fetchedLanguages);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const taskType = useWatch({ control: form.control, name: 'task.type' });

  const toggleLanguageGroup = (group: string) => {
    const currentSelection = targetLanguageGroups.includes(group);
    let updatedSelection: string[];

    if (currentSelection) {
      updatedSelection = targetLanguageGroups.filter(g => g !== group);
    } else {
      updatedSelection = [...targetLanguageGroups, group];
    }

    form.setValue('task.targetLanguageGroups', updatedSelection, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const ruCountries = countries
    .filter(country => country.code === 'ru')
    .map(country => country.code);

  const toggleCountryGroup = (group: string) => {
    const currentSelection = targetCountryGroups?.includes(group) || false;
    let updatedSelection;

    if (group === 'ru') {
      updatedSelection = currentSelection
        ? targetCountryGroups.filter(g => g !== group)
        : [...(targetCountryGroups || []), group, ...ruCountries];
    } else {
      updatedSelection = currentSelection
        ? targetCountryGroups.filter(g => g !== group)
        : [...(targetCountryGroups || []), group];
    }

    form.setValue('task.targetCountryGroups', updatedSelection, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const languagesToRender =
    targetLanguageGroups.length > 0
      ? languages
          .filter(lang => targetLanguageGroups.includes(lang.code))
          .map(lang => lang.code)
      : ['en', 'ru'];

  return (
    <>
      {isEdit && (
        <fieldset className="mb-[15px] ml-1.5 flex">
          <label
            htmlFor="isActive"
            className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
          >
            Task enabled
          </label>
          <Switch id="isActive" {...form.register('task.isActive')} />
        </fieldset>
      )}
      <fieldset className="mb-[15px] flex flex-col">
        <Select
          variant="auth"
          readOnly={isEdit}
          disabled={isEdit}
          state={form.formState.errors.task?.type ? 'error' : undefined}
          extra="w-full mb-3"
          label="Task type"
          id="type"
          {...form.register('task.type', {
            required: 'This field is required',
          })}
        >
          <option value="" hidden>
            Select task type
          </option>
          {Object.values(TaskType).map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </fieldset>
      {taskType === TaskType.SNAPSHOT && (
        <fieldset className="mb-[15px] flex flex-col">
          <label className="mb-2">Upload image</label>
          <div className="relative">
            <input
              type="file"
              id="image"
              accept="image/png, image/jpeg"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              onChange={handleFileChange}
            />
            <button
              type="button"
              disabled={isIconUploading}
              className="w-full bg-blue-500 text-white rounded-full py-2 text-center"
              onClick={() => document.getElementById('image').click()}
            >
              {isIconUploading ? (
                <ClipLoader size={20} color="#ffffff" />
              ) : form.getValues('task.iconUrl') ? (
                'Change file'
              ) : (
                'Attach file'
              )}
            </button>
          </div>
          {(selectedFile || form.getValues('task.iconUrl')) && (
            <div className="mt-3 p-2 bg-gray-100 rounded-lg text-sm flex justify-between items-center">
              <span>
                Selected file: {selectedFile || form.getValues('task.iconUrl')}
              </span>
              <button
                type="button"
                className="ml-2 text-red-500 hover:text-red-700"
                onClick={handleRemoveFile}
                title="Remove file"
              >
                <FaTimes />
              </button>
            </div>
          )}
          {form.formState.errors.task?.iconUrl && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.task?.iconUrl.message}
            </span>
          )}
        </fieldset>
      )}

      {languagesToRender.map(languageCode => (
        <div key={languageCode}>
          <fieldset className="mb-[15px] flex flex-col">
            <InputField
              variant="auth"
              state={
                form.formState.errors.task?.title?.[languageCode]
                  ? 'error'
                  : undefined
              }
              extra="w-full mb-3"
              label={`Title (${languageCode})`}
              placeholder={`Enter task title in ${languageCode}`}
              id={`title-${languageCode}`}
              {...form.register(`task.title.${languageCode}`, {
                required: {
                  value: true,
                  message: `Title is required in ${languageCode}`,
                },
                validate: value => {
                  if (typeof value !== 'string') {
                    return 'Invalid input';
                  }
                  return true;
                },
              })}
            />
          </fieldset>
          <fieldset className="mb-[15px] flex flex-col">
            <TextArea
              variant="auth"
              state={
                form.formState.errors.task?.description?.[languageCode]
                  ? 'error'
                  : undefined
              }
              extra="w-full mb-3"
              label={`Description (${languageCode})`}
              placeholder={`Enter task description in ${languageCode}`}
              id={`description-${languageCode}`}
              {...form.register(`task.description.${languageCode}`, {
                required: {
                  value: true,
                  message: `Description is required in ${languageCode}`,
                },
                validate: value => {
                  if (typeof value !== 'string') {
                    return 'Invalid input';
                  }
                  return true;
                },
              })}
            />
          </fieldset>
        </div>
      ))}

      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={
            form.formState.errors.task?.rewardPointsAmount ? 'error' : undefined
          }
          extra="w-full mb-3"
          label="Reward (points)"
          placeholder="Enter reward points"
          id="rewardPointsAmount"
          type="number"
          onWheel={preventScrollOnNumberInput}
          {...form.register('task.rewardPointsAmount', {
            required: 'This field is required',
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: 'Invalid number format',
            },
          })}
        />
        {form.formState.errors.task?.rewardPointsAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.task?.rewardPointsAmount.message}
          </span>
        )}
      </fieldset>

      <fieldset className="mb-[15px] flex flex-col">
        <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
          Target Groups
        </label>
        <div className="space-y-2">
          {['ru', 'en'].map(group => (
            <label key={group} className="flex items-center">
              <input
                type="checkbox"
                id={`language-group-${group}`}
                checked={targetLanguageGroups?.includes(group)}
                onChange={() => {
                  toggleLanguageGroup(group);
                  toggleCountryGroup(group);
                }}
              />
              <span className="ml-2">{group}</span>
            </label>
          ))}
        </div>
      </fieldset>
      {taskType === TaskType.TELEGRAM && (
        <div className="flex flex-col md:flex-row gap-4">
          <fieldset className="mb-[15px] flex flex-col grow">
            <InputField
              variant="auth"
              state={
                form.formState.errors.telegramTask?.resourceId
                  ? 'error'
                  : undefined
              }
              extra="w-full mb-3"
              label="Telegram Resource ID"
              placeholder="Enter Telegram Resource ID"
              readOnly={isEdit}
              disabled={isEdit}
              id="resourceId"
              type="number"
              onWheel={preventScrollOnNumberInput}
              {...form.register('telegramTask.resourceId', {
                disabled: isEdit,
                required: 'This field is required',
              })}
            />
            {form.formState.errors.telegramTask?.resourceId && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.telegramTask?.resourceId.message}
              </span>
            )}
          </fieldset>
          <fieldset className="mb-[15px] flex flex-col grow">
            <InputField
              variant="auth"
              state={
                form.formState.errors.task?.redirectUrl ? 'error' : undefined
              }
              extra="w-full mb-3"
              label="Redirect URL"
              placeholder="https://t.me/ or other URL"
              id="redirectUrl"
              type="url"
              {...form.register('task.redirectUrl', {
                required: 'This field is required',
              })}
            />
            {form.formState.errors.task?.redirectUrl && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.task?.redirectUrl.message}
              </span>
            )}
          </fieldset>
        </div>
      )}
      {taskType === TaskType.SNAPSHOT && (
        <>
          <fieldset className="mb-[15px] flex flex-col">
            <InputField
              variant="auth"
              state={
                form.formState.errors.task?.redirectUrl ? 'error' : undefined
              }
              extra="w-full mb-3"
              label="Redirect URL"
              placeholder="https://example.com/"
              id="redirectUrl"
              type="url"
              {...form.register('task.redirectUrl', {
                required: 'This field is required',
              })}
            />
            {form.formState.errors.task?.redirectUrl && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.task?.redirectUrl.message}
              </span>
            )}
          </fieldset>
        </>
      )}
      {taskType === TaskType.DISCORD && (
        <div className="flex flex-col md:flex-row gap-4">
          <fieldset className="mb-[15px] flex flex-col grow">
            <InputField
              variant="auth"
              state={
                form.formState.errors.discordTask?.resourceId
                  ? 'error'
                  : undefined
              }
              extra="w-full mb-3"
              label="Discord Resource ID"
              placeholder="Enter Discord Resource ID"
              readOnly={isEdit}
              disabled={isEdit}
              id="resourceId"
              type="number"
              onWheel={preventScrollOnNumberInput}
              {...form.register('discordTask.resourceId', {
                disabled: isEdit,
                required: 'This field is required',
              })}
            />
            {form.formState.errors.discordTask?.resourceId && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.discordTask?.resourceId.message}
              </span>
            )}
          </fieldset>
          <fieldset className="mb-[15px] flex flex-col grow">
            <InputField
              variant="auth"
              state={
                form.formState.errors.task?.redirectUrl ? 'error' : undefined
              }
              extra="w-full mb-3"
              label="Redirect URL"
              placeholder="https://example.com/"
              id="redirectUrl"
              type="url"
              {...form.register('task.redirectUrl', {
                required: 'This field is required',
              })}
            />
            {form.formState.errors.task?.redirectUrl && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.task?.redirectUrl.message}
              </span>
            )}
          </fieldset>
        </div>
      )}
    </>
  );
}
