import { AxiosError } from 'axios';
import { $api } from './interceptor';
import { Paginated } from 'types/paginated';
import { Request } from 'types/requests';
import { toast } from 'App';
import { BanSheme } from 'views/admin/marketplace/scheme/BanScheme';

const requestsApi = () => {
  const getPaginatedRequests = async ({
    page = 0,
    pageSize = 10,
    searchValue,
  }: {
    page?: number;
    pageSize?: number;
    searchValue?: string;
  }): Promise<Paginated<Request>> => {
    const { data } = await $api.get<Paginated<Request>>(
      '/api/snapshot-tasks/results',
      {
        params: {
          page: page + 1,
          limit: pageSize,
          search: searchValue,
          'filter.status': '$eq:REVIEW',
        },
      }
    );

    return data;
  };

  const getRequest = async (id: string) => {
    try {
      const { data } = await $api.get<Paginated<Request>>(
        '/api/snapshot-tasks/results',
        {
          params: { search: id },
        }
      );
      return data.data?.at(0);
    } catch (error) {
      console.log(error);
    }
  };

  const approveRequest = async (id: Request['id']) => {
    try {
      await $api.post(`api/snapshot-tasks/approve/${id}`);

      toast({
        title: 'Task approved.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const approveAllRequests = async () => {
    try {
      await $api.post(`api/snapshot-tasks/all/approve`);

      toast({
        title: 'All requests approved.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const rejectRequest = async (id: Request['id']) => {
    try {
      await $api.post(`api/snapshot-tasks/reject/${id}`);

      toast({
        title: 'Task rejected.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const banUser = async (data: BanSheme, userId: string) => {
    const payload = {
      type: data.type,
      reason: data.reason,
      expiresAt: data.expiresAt,
    };

    try {
      await $api.post(`api/bans/users/${userId}/ban`, payload);

      toast({
        title: 'User banned.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message || 'An error occurred.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  return {
    getRequest,
    approveRequest,
    approveAllRequests,
    rejectRequest,
    banUser,
    getPaginatedRequests,
  };
};

export const requestsService = requestsApi();
