import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { ButtonSM } from 'components/button/Small';
import Card from 'components/card';
import { useMemo, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import useSWR from 'swr';
import { Request } from 'types/requests';
import { useDebounceValue } from 'usehooks-ts';
import { requestsService } from 'views/services/requestsService';
import Pagination from '../../../components/Pagination/Pagination';
import { RequestDialog } from './components/RequestDialog';

const RequestsView = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [searchDebounce] = useDebounceValue(search, 1000);
  const [stopAutoRefresh, setStopAutoRefresh] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/requests?page=${page}&search=${searchDebounce}&limit=${itemsPerPage}`,
    () =>
      requestsService.getPaginatedRequests({
        page: page,
        pageSize: itemsPerPage,
        searchValue: searchDebounce,
      }),
    {
      refreshInterval: stopAutoRefresh ? null : 5000,
      revalidateOnFocus: !stopAutoRefresh,
    }
  );

  const totalPages = Math.ceil(
    (tableData?.meta.totalItems ?? 0) / itemsPerPage
  );

  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setPage(0);
  };

  const handleApprove = async (request: Request) => {
    if (
      window.confirm(
        `Are you sure you want to approve request for ${request.userTask.user.username}?`
      )
    ) {
      await requestsService.approveRequest(request.userTask.id);
      await mutate();
    }
  };

  const handleAllApprove = async () => {
    if (window.confirm(`Are you sure you want to approve all requests?`)) {
      await requestsService.approveAllRequests();
      await mutate();
    }
  };

  const handleReject = async (request: Request) => {
    if (
      window.confirm(
        `Are you sure you want to reject request for ${request.userTask.user.username}?`
      )
    ) {
      await requestsService.rejectRequest(request.userTask.id);
      await mutate();
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('userTask.task.title.en', {
        id: 'name',
        header: () => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Task
          </p>
        ),
        cell: info => (
          <div className="flex items-center gap-2">
            <p className="text-md font-medium text-navy-700 dark:text-white">
              <RequestDialog
                requestId={info.row.original.id}
                onOpenChange={isOpen => setStopAutoRefresh(isOpen)}
                onApproveOrReject={mutate}
              >
                <button className="text-md dark:text-whit font-medium underline">
                  {info.getValue() ||
                    info?.row?.original?.userTask?.task?.title?.ru ||
                    'n/a'}
                </button>
              </RequestDialog>
            </p>
          </div>
        ),
      }),
      columnHelper.accessor('userTask.user.username', {
        id: 'username',
        header: () => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            User Name
          </p>
        ),
        cell: info => (
          <a
            href={`https://t.me/${info.getValue()}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-md flex items-center gap-2 font-medium underline dark:text-white"
          >
            {info.getValue()} <MdOpenInNew />
          </a>
        ),
      }),
      columnHelper.accessor('id', {
        id: 'actions',
        header: () => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        ),
        cell: info => (
          <>
            <div className="flex gap-2 font-bold">
              <ButtonSM
                variant="primary"
                onClick={() => handleApprove(info.row.original)}
              >
                Approve
              </ButtonSM>
              <ButtonSM
                variant="secondary"
                onClick={() => handleReject(info.row.original)}
              >
                Reject
              </ButtonSM>
            </div>
          </>
        ),
      }),
    ],
    []
  );

  const [data] = useDebounceValue(tableData?.data ?? [], 100);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter: search,
    },
    onGlobalFilterChange: setSearch,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Card
        extra={'w-full h-[800px] md:h-[500px] sm:overflow-auto px-6 pb-4 mt-8'}
      >
        <div className="relative flex flex-col items-center justify-between gap-2 pt-4 sm:flex-row sm:gap-2">
          <div className="flex w-full flex-col gap-2 sm:w-auto sm:flex-row sm:items-center">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Requests
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <ButtonSM variant="primary" onClick={handleAllApprove}>
              Approve all
            </ButtonSM>

            <input
              type="text"
              placeholder="Search"
              className="block min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit sm:max-w-96"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                setPage(0);
              }}
            />
          </div>
        </div>

        <div className="mt-8 grow overflow-x-auto xl:overflow-x-hidden">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-white pb-2 pr-4 pt-4 text-start dark:bg-navy-800',
                          header.column.getCanSort() && 'cursor-pointer'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData?.data?.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData?.data?.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className="[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900"
                    >
                      {row.getVisibleCells().map(cell => {
                        console.log({
                          cell,
                          context: cell.getContext(),
                          info: cell.column.columnDef.cell,
                          flex: flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          ),
                        });
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-2 pr-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            ) || 'x'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={page}
          totalItems={tableData?.meta.totalItems}
          totalPages={totalPages}
          onPageChange={setPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </Card>
    </>
  );
};

const columnHelper = createColumnHelper<Request>();

export default RequestsView;
