export enum TaskType {
  TELEGRAM = 'TELEGRAM',
  SNAPSHOT = 'SNAPSHOT',
  DISCORD = 'DISCORD',
}

export enum TelegramTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
}

export enum DiscordTaskType {
  JOIN_SERVER = 'JOIN_SERVER',
}

export enum TwitterTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
  LIKE = 'LIKE_POST',
  RETWEET_POST = 'RETWEET_POST',
}

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TasksTab {
  PUSSY = 'Pussy',
  PARTNERS = 'Partners',
}

export interface Task {
  id: string;
  type: TaskType;
  availableFromLevel: number;
  title: {
    [key: string]: string;
  };
  rewardPointsAmount: number;
  description: {
    [key: string]: string;
  };
  status: TaskStatus;
  redirectUrl: string;
  telegramResourceId?: number;
  order: number;
  iconUrl?: string;
  inviteCount?: number;
  usersCompletedCount?: number;
  usersTotalCount?: number;
  targetLanguageGroups?: string[] | null;
  targetCountryGroups?: string[] | null;
}
