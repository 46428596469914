import InputField from 'components/fields/InputField';
import { useFormContext, useWatch } from 'react-hook-form';

import { UTMFormScheme } from '../../scheme/form';
import { transformUTMName } from 'utils/utm';

interface UTMSourceFormProps {
  isEdit: boolean;
}

export function UTMSourceForm({ isEdit }: UTMSourceFormProps) {
  const form = useFormContext<UTMFormScheme>();

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const utmName = useWatch({ name: 'name' });

  const transformedName = transformUTMName(utmName);

  return (
    <>
      <div className="flex flex-col">
        <fieldset className="mb-[15px] flex flex-col">
          <InputField
            variant="auth"
            state={form.formState.errors.name ? 'error' : undefined}
            extra="w-full mb-1"
            label="Name"
            placeholder="Subscribe to our Telegram channel and get 10 points"
            id="name"
            {...form.register('name', {
              required: 'This field is required',
            })}
          />
          <span className="text-sm text-gray-500">
            ?start=utmSource-
            <span className="text-[#000] dark:text-white">
              {transformedName}
            </span>
          </span>
          {form.formState.errors.name && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.name.message}
            </span>
          )}
        </fieldset>
      </div>
    </>
  );
}
