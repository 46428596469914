import * as Dialog from '@radix-ui/react-dialog';
import { Button } from 'components/button';
import { DangerButton } from 'components/button/Danger';
import { SuccessButton } from 'components/button/Success';
import TextArea from 'components/fields/TextArea';
import { EN_REGEX, RU_REGEX } from 'constants/regex';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import useSWR from 'swr';
import { BanType } from 'types/requests';
import { BanSheme } from 'views/admin/marketplace/scheme/BanScheme';
import { requestsService } from 'views/services/requestsService';

interface UserDialogProps {
  requestId: string;
  children: React.ReactNode;
  onOpenChange: (isOpen: boolean) => void;
  onApproveOrReject: () => Promise<unknown>;
}

export function RequestDialog({
  requestId,
  onOpenChange,
  children,
  onApproveOrReject,
}: UserDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: request, isLoading } = useSWR(
    isOpen ? `/request/${requestId}` : null,
    () => requestsService.getRequest(requestId)
  );

  const [banReason, setBanReason] = useState({
    en: '',
    ru: '',
  });
  const [banModalOpen, setBanModalOpen] = useState(false);
  const [expiresAt, setExpiresAt] = useState(new Date().toISOString());
  const [banType, setBanType] = useState<BanType | null>(null);
  const [errors, setErrors] = useState({
    en: false,
    ru: false,
  });

  const handleApprove = async () => {
    if (
      window.confirm(
        `Are you sure you want to approve request for ${request.userTask.user.username}?`
      )
    ) {
      await requestsService.approveRequest(request.userTask.id);
      await onApproveOrReject();
    }
  };

  const handleReject = async () => {
    if (
      window.confirm(
        `Are you sure you want to reject request for ${request.userTask.user.username}?`
      )
    ) {
      await requestsService.rejectRequest(request.userTask.id);
      await onApproveOrReject();
    }
  };

  const handleBan = async () => {
    setErrors({ en: false, ru: false });
    const newErrors = {
      en: false,
      ru: false,
    };
    if (
      request?.userTask?.user.language_code &&
      Object.values(request.userTask.user.language_code).includes('en') &&
      !banReason.en.trim()
    ) {
      newErrors.en = true;
    }

    if (
      request?.userTask?.user.language_code &&
      Object.values(request.userTask.user.language_code).includes('ru') &&
      !banReason.ru.trim()
    ) {
      newErrors.ru = true;
    }
    setErrors(newErrors);

    if (newErrors.en || newErrors.ru) {
      alert('Please fill in the required reason(s).');
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to ban ${request.userTask.user.username}?`
      )
    ) {
      if (!banType) {
        alert('Please select a ban type.');
        return;
      }

      const reason: { [key: string]: string } = {
        en: banReason.en?.trim() || undefined,
        ru: banReason.ru?.trim() || undefined,
      };

      const expirationDate = new Date(expiresAt);

      const banData: BanSheme = {
        type: banType,
        reason,
        expiresAt:
          banType === 'TEMPORARY' ? expirationDate.toISOString() : undefined,
      };

      await requestsService.banUser(banData, request.userTask.user.id);

      setBanModalOpen(false);
    }
  };

  return (
    <Dialog.Root
      modal
      open={isOpen}
      onOpenChange={isOpen => {
        setIsOpen(isOpen);
        onOpenChange(isOpen);
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
        <Dialog.Content
          aria-describedby="request-dialog-title"
          onInteractOutside={e => {
            if ((e.target as HTMLElement).closest('.chakra-toast')) {
              e.preventDefault();
            }
          }}
          className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
        >
          <Dialog.Title
            id="request-dialog-title"
            className="text-mauve12 mb-10 text-2xl font-medium"
          >
            {isLoading
              ? 'Loading...'
              : `Request from @${request?.userTask.user.username}`}
          </Dialog.Title>
          {isLoading && (
            <div className="flex h-[292px] items-center justify-center">
              <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
            </div>
          )}
          {!isLoading && request && (
            <>
              <div className="max-h-[calc(85vh_-_227px)] min-h-[292px] grow overflow-x-auto px-px">
                <ul className="flex flex-col gap-4 text-lg">
                  <li>
                    <b>STATUS:</b>{' '}
                    <span className="text-base text-gray-500">
                      {request.status}
                    </span>
                  </li>
                  <li>
                    <b>Task name:</b>
                    <span className="text-base text-gray-500">
                      {request.userTask.task.title.en ||
                        Object.values(request.userTask.task.title)[0]}
                    </span>
                  </li>
                  <li>
                    <b>Description:</b>
                    <span className="text-base text-gray-500">
                      {request.userTask.task.description.en ||
                        Object.values(request.userTask.task.description)[0]}
                    </span>
                  </li>
                </ul>
                <div className="mt-4">
                  <b className="mb-2 block text-lg">Results:</b>
                  {(!request.snapshotsUrls ||
                    request.snapshotsUrls?.length <= 0) && (
                    <span className="text-base text-gray-500">
                      No result yet
                    </span>
                  )}
                  {request.snapshotsUrls?.length > 0 &&
                    request.snapshotsUrls?.map((imgSrc, i) => (
                      <a
                        href={imgSrc}
                        className="flex outline-none"
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                      >
                        <img
                          width={400}
                          src={imgSrc}
                          className="w-full max-w-[300px] object-contain outline-none"
                          alt="requestImage"
                        />
                      </a>
                    ))}
                </div>
              </div>
            </>
          )}
          <div className="mt-[10px] relative bottom-8 flex flex-col gap-2">
            <div className="flex gap-2 items-end">
              <SuccessButton
                className="w-full justify-center h-[40px]"
                onClick={handleApprove}
              >
                Approve
              </SuccessButton>
              <div className="flex flex-col gap-2">
                <DangerButton
                  className="w-full justify-center"
                  onClick={() => setBanModalOpen(true)}
                >
                  Ban
                </DangerButton>
                <DangerButton
                  className="w-full justify-center"
                  onClick={handleReject}
                >
                  Reject
                </DangerButton>
              </div>
            </div>
            <Dialog.Close asChild>
              <Button className="w-full justify-center">Close</Button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              type="button"
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <MdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>

      <Dialog.Root modal open={banModalOpen} onOpenChange={setBanModalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content className="fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-lg focus:outline-none">
            <Dialog.Title className="text-xl font-medium mb-4">
              Ban User
            </Dialog.Title>
            <div className="flex flex-col gap-4">
              <div>
                <label>
                  <input
                    type="radio"
                    value="PERMANENT"
                    checked={banType === 'PERMANENT'}
                    onChange={() => setBanType('PERMANENT')}
                  />
                  Permanent Ban
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    value="TEMPORARY"
                    checked={banType === 'TEMPORARY'}
                    onChange={() => setBanType('TEMPORARY')}
                  />
                  Temporary Ban
                </label>
              </div>

              <fieldset className="flex flex-col">
                <label className="mb-2">
                  Enter reason for ban (optionally)
                </label>
                <TextArea
                  variant="auth"
                  state={
                    (banReason.en && !EN_REGEX.test(banReason.en)) || errors.en
                      ? 'error'
                      : undefined
                  }
                  extra="w-full"
                  label={`Reason (EN)`}
                  placeholder={`Enter reason in English`}
                  id={`reason-en`}
                  value={banReason.en}
                  onChange={e =>
                    setBanReason(prev => ({
                      ...prev,
                      en: e.target.value,
                    }))
                  }
                />
              </fieldset>
              <fieldset className="mb-[15px] flex flex-col">
                <TextArea
                  variant="auth"
                  state={
                    (banReason.ru && !RU_REGEX.test(banReason.ru)) || errors.ru
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label={`Reason (RU)`}
                  placeholder={`Enter reason in Russian`}
                  id={`reason-ru`}
                  value={banReason.ru}
                  onChange={e =>
                    setBanReason(prev => ({
                      ...prev,
                      ru: e.target.value,
                    }))
                  }
                />
              </fieldset>
              {banType === 'TEMPORARY' && (
                <div>
                  <label>
                    Expires At:
                    <input
                      type="datetime-local"
                      value={expiresAt}
                      onChange={e => setExpiresAt(e.target.value)}
                    />
                  </label>
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-end">
              <Button onClick={() => setBanModalOpen(false)} className="mr-2">
                Cancel
              </Button>
              <SuccessButton onClick={handleBan}>Confirm Ban</SuccessButton>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </Dialog.Root>
  );
}
