import { URL_REGEXP } from 'constants/regexp';

export function escapeSpecialCharsInURLs(text: string): string {
  return text.replace(URL_REGEXP, url => {
    // Replace each special character in the URL with its escaped version

    // '_', '*', '[', ']', '(', ')', '~', '`', '>', '#', '+', '-', '=', '|', '{', '}', '.', '!'
    return url.replace(/([_*[\]()~`>#+\-=|{}.!])/g, '\\$1');
  });
}
