import { FieldError, useFormContext } from 'react-hook-form';
import InputField from 'components/fields/InputField';

interface TaskFormScheme {
  task: {
    message: string;
  };
}

interface TaskFormErrors {
  errors: {
    task?: {
      message?: FieldError;
    };
  };
  task?: {
    message?: FieldError;
  };
}

interface MessFormProps {
  isEdit: boolean;
}

export function MessForm({ isEdit }: MessFormProps) {
  const { register, formState } = useFormContext<TaskFormScheme>();

  if (!register || !formState) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const { errors } = formState as TaskFormErrors;
  const messageError = errors.task?.message?.message as string | undefined;

  return (
    <fieldset className="mb-[15px] flex flex-col">
      <InputField
        variant="auth"
        state={messageError ? 'error' : undefined}
        extra="w-full mb-3"
        label="Message"
        placeholder="Enter your message here"
        id="message"
        type="text"
        {...register('task.message', {
          required: 'This field is required',
        })}
      />
      {messageError && (
        <span className="text-sm text-red-500 dark:text-red-400">
          {messageError}
        </span>
      )}
    </fieldset>
  );
}
