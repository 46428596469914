import { toast } from 'App';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { Button } from 'components/button';
import { DangerButton } from 'components/button/Danger';
import Card from 'components/card';
import Switch from 'components/switch';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import { escapeSpecialCharsInURLs } from 'utils/telegram';
import { communicationService } from 'views/services/communicationService';
import { uploadImage } from 'views/services/uploadImage';

export const CommunicationView = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [telegramPushMessage, setTelegramPushMessage] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [imagesUrls, setImagesUrls] = useState<string[]>(['']);
  const [addImage, setAddImage] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);

  const { register } = useForm();

  const onPushTelegramMessage = async () => {
    setIsSubmitting(true);
    setError(undefined);

    try {
      const escapedText = escapeSpecialCharsInURLs(telegramPushMessage);

      await communicationService.pushTelegramMessage({
        message: escapedText,
        imagesUrls: addImage ? imagesUrls.filter(url => url) : undefined,
      });
      setTelegramPushMessage('');
      setImagesUrls(['']);
    } catch (error) {
      console.error(error);
      setError('Failed to send message');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUrlChange = (index: number, value: string) => {
    const updatedUrls = [...imagesUrls];
    updatedUrls[index] = value;
    setImagesUrls(updatedUrls);
  };

  const addImageUrlField = () => {
    setImagesUrls([...imagesUrls, '']);
  };

  const removeImageUrlField = (index: number) => {
    const updatedUrls = [...imagesUrls];
    updatedUrls.splice(index, 1);
    setImagesUrls(updatedUrls.length > 0 ? updatedUrls : ['']);
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files?.[0];

    try {
      setUploading(true);
      setUploadingIndex(index);

      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        throw new Error('Please upload a valid image file (JPEG or PNG)');
      }

      const response = await uploadImage.upload([file]);
      const imageUrl = response[0]?.imageUrl;

      if (imageUrl) {
        handleImageUrlChange(index, imageUrl);
      }
    } catch (error) {
      console.error('File upload error:', error);
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response.data.message || error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
    } finally {
      setUploading(false);
      setUploadingIndex(null);
    }
  };

  return (
    <div className="mt-10 grid grid-cols-1 gap-5">
      <div className="w-full">
        <Card extra={'w-full h-fit sm:overflow-auto px-6 pb-6'}>
          <header className="relative flex items-center gap-4 pt-4">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Push telegram message
            </div>
          </header>

          <textarea
            className="w-full min-h-[200px] mt-8 h-full min-w-[300px] resize-none outline-none p-2 mb-4 rounded-xl bg-gray-50 odd:dark:bg-navy-900"
            onChange={e => {
              setTelegramPushMessage(e.target.value);
              setError(undefined);
            }}
            value={telegramPushMessage}
            placeholder="Message"
          />

          <div className="flex items-center gap-3 mb-4">
            <div className="flex items-center gap-2">
              <Switch
                id="addImage"
                {...register('addImage')}
                checked={addImage}
                onChange={() => setAddImage(!addImage)}
              />
              <span>Add image</span>
            </div>
          </div>

          {addImage &&
            imagesUrls.map((url, index) => (
              <div key={index} className="mb-3 flex items-center gap-2">
                <input
                  className="w-full p-2 rounded-xl bg-gray-50 dark:bg-navy-900"
                  type="url"
                  placeholder={`Image URL ${index + 1}`}
                  value={url}
                  onChange={e => handleImageUrlChange(index, e.target.value)}
                />

                <label
                  className={clsx(
                    `flex text-nowrap min-w-[115px] min-h-[40px] justify-center items-center max-w-[300px] gap-2 rounded-xl bg-brand-500
                  px-5 py-2 text-base font-medium text-white transition
                  duration-200 hover:bg-brand-600 active:bg-brand-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                  dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 cursor-pointer`,
                    isUploading &&
                      uploadingIndex === index &&
                      'pointer-events-none opacity-50'
                  )}
                >
                  {isUploading && uploadingIndex === index ? (
                    <ClipLoader size={20} color="#ffffff" />
                  ) : (
                    'Attach File'
                  )}
                  <input
                    type="file"
                    className="hidden"
                    accept="image/jpeg, image/png"
                    onChange={e => handleFileUpload(e, index)}
                    disabled={isUploading && uploadingIndex === index}
                  />
                </label>
                <DangerButton onClick={() => removeImageUrlField(index)}>
                  Remove
                </DangerButton>
              </div>
            ))}

          {addImage && (
            <Button onClick={addImageUrlField} className="mb-4">
              Add Image URL
            </Button>
          )}

          <Button
            disabled={isSubmitting || !telegramPushMessage}
            onClick={onPushTelegramMessage}
          >
            {isSubmitting ? 'Sending...' : 'Send'}
          </Button>
          {error && <div className="text-red-500">{error}</div>}
        </Card>
      </div>
    </div>
  );
};
