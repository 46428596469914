import { MdGroup, MdLocationOn } from 'react-icons/md';

import Widget from 'components/widget/Widget';
import UsersTable from 'views/admin/default/components/UsersTable';

import useSWR from 'swr';
import { usersService } from 'views/services/usersService';
import { combosService } from 'views/services/comboService';
import GeoCard from 'components/GeoCard';

const Dashboard = () => {
  const { data: statistics, isLoading } = useSWR(
    '/users-statistics',
    () => usersService.getUsersStatistic(),
    {
      refreshInterval: 5000,
    }
  );

  const { data: combo } = useSWR('/combo', () =>
    combosService.getCurrentCombo()
  );

  return (
    <div>
      <div className="mt-3 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <div className="w-fit gap-2 flex flex-row">
          <Widget
            icon={<MdGroup className="h-7 w-7" />}
            title={'Total users'}
            subtitle={
              isLoading ? 'Loading...' : statistics?.totalUsersCount?.toString()
            }
          />
          <GeoCard
            icon={<MdLocationOn className="h-7 w-7" />}
            title={'User`s Geo Statistic'}
          />
        </div>

        {!!combo && (
          <div className="capitalize dark:text-white text-lg font-semibold flex flex-col items-center">
            Today secret combo:
            <p className="opacity-70 text-md">{combo.sequence.join('-')}</p>
          </div>
        )}
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5">
        <div className="w-full">
          <UsersTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
