import Card from 'components/card';
import { ReactNode, useState } from 'react';
import { GeoModal } from 'views/admin/default/components/GeoModal';

const GeoCard = (props: { icon: JSX.Element; title: ReactNode }) => {
  const { icon, title } = props;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleCardClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
      setIsModalOpened(true);
    }, 150);
  };

  const handleModalClose = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      <div
        className={`cursor-pointer transition-transform duration-150 ${
          isClicked ? 'scale-95' : 'scale-100'
        }`}
        onClick={handleCardClick}
      >
        <Card extra="!flex-row flex-grow items-center rounded-[10px] pr-[20px]">
          <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
            <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
              <span className="flex items-center text-brand-500 dark:text-white">
                {icon}
              </span>
            </div>
          </div>

          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
          </div>
        </Card>
      </div>
      <GeoModal isOpen={isModalOpened} onOpenChange={handleModalClose} />
    </>
  );
};

export default GeoCard;
