import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import ClipLoader from 'react-spinners/ClipLoader';
import { Geo } from 'views/types/geoStatistic';
import { usersService } from 'views/services/usersService';

interface GeoModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const GeoModal = ({ isOpen, onOpenChange }: GeoModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatistic] = useState<Geo[]>([]);

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedLanguages = await usersService.getGeoStatistic();
        setStatistic(fetchedLanguages || []);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-gray-800 bg-opacity-60" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[99] max-h-[85vh] w-[90vw] max-w-[450px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-lg focus:outline-none dark:bg-gray-900 dark:text-white">
          <div className="flex flex-row justify-between items-center mb-4">
            <h1 className="font-dm text-md font-medium text-gray-600">
              User`s Geo Statistic
            </h1>
            <Dialog.Close asChild>
              <button
                type="button"
                className="absolute right-4 top-4 p-2 rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-400"
                aria-label="Close"
              >
                <MdClose className="w-4 h-4" />
              </button>
            </Dialog.Close>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center h-40">
              <ClipLoader color="#000000" />
            </div>
          ) : (
            <div className="space-y-4 max-h-[260px] overflow-y-auto thin-scrollbar">
              {statistic.length === 0 ? (
                <p className="text-center text-gray-600">No data available.</p>
              ) : (
                <ul className="space-y-2">
                  {statistic.map((item, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center p-3 rounded-lg bg-gray-50 dark:bg-gray-800"
                    >
                      <div className="text-sm font-medium text-gray-800 dark:text-gray-200">
                        {item.country}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {item.percentage}% ({item.userCount} users)
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
